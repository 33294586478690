/* super-Large Screen */
@media screen and (max-width: 6000px) {
  .img-ig {
    width: 99%;
    height: 350px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    margin-left: 90px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 10px;
  }
}

/* Xtra-Large Screen */
@media screen and (max-width: 2800px) {
  .img-ig {
    width: 99%;
    height: 350px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    margin-left: 135px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 10px;
  }
}

/* Large Screen */
@media screen and (max-width: 1400px) {
  .img-ig {
    width: 99%;
    height: 490px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 90px;
    height: 30px;
    border-radius: 3px;
    margin-left: 250px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .img-ig {
    width: 99%;
    height: 430px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 90px;
    height: 30px;
    border-radius: 3px;
    margin-left: 160px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 13px;
  }
}

@media screen and (max-width: 1080px) {
  .img-ig {
    width: 99%;
    height: 350px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    margin-left: 170px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 10px;
  }
}

@media screen and (max-width: 1020px) {
  .img-ig {
    width: 99%;
    height: 430px;
  }
  .followers-count {
    font-size: 13px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    margin-left: 150px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 10px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 380px) {
  .img-ig {
    width: 97%;
    height: 270px;
  }

  .icon-svg {
    stroke-width: 2px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 70px;
    height: 30px;
    border-radius: 3px;
    margin-left: 80px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 9px;
  }
}

@media screen and (max-width: 460px) {
  .img-ig {
    width: 97%;
    height: 240px;
  }

  .icon-svg {
    stroke-width: 2px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 70px;
    height: 30px;
    border-radius: 3px;
    margin-left: 120px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 9px;
  }
}

@media screen and (max-width: 410px) {
  .img-ig {
    width: 97%;
    height: 270px;
  }

  .icon-svg {
    stroke-width: 2px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 70px;
    height: 30px;
    border-radius: 3px;
    margin-left: 90px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 9px;
  }
}

@media screen and (max-width: 370px) {
  .img-ig {
    width: 97%;
    height: 270px;
  }

  .icon-svg {
    stroke-width: 2px;
  }

  .instagram-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
  }

  .header-ig {
    max-height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .btn-view-profile {
    width: 70px;
    height: 30px;
    border-radius: 3px;
    margin-left: 70px;
    margin-top: 4px;
  }

  .btn-view-profile span {
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
    font-size: 9px;
  }
}
