.app-container {
  background-color: #fef0e2;
  min-height: 120vh;
}

.col-flex {
  display: flex;
}

.row-align-right {
  display: block;
  align-items: flex-end;
}

.text-signup {
  color: #7F7B77;
  font-family: PT Sans Narrow Bold;
  font-weight: bold;
}

.bar-header {
  background-color: #719ED9;
}

.text-header {
  color: #D4EFFF;
  font-weight: bold;
  font-family: 'PT Sans Narrow Bold';
  margin-left: 20px;
}

.row-input {
  margin-top: 10px;
}

.Btn-Blue-BG:active:focus {
  background-color: red;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
  background-color: gray;
  border-color: gray;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle){
  background-color: gray;
  border-color: gray;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #2D89EF;
  border-color: #2D89EF;
}

.btn-primary:active:focus {
  background-color: orange;
}

.input-text {
  width: 300px;
  background-color: #fff;
}

.label-validation {
  color: red;
  font-weight: bold;
  font-size: 15px;
}

.button {
  background-color: #2D89EF;
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}